const OnboardingQuestionnaire = {
    id: "onboarding",
    title: "Onboarding Questionnaire",
    done_title: "You are ready to go!",
    done_text: "Great! You're all set to start using prefiQuest. You can always update your answers later in your profile settings.",
    done_button_text: "Start your Quest!",

    questions: [
        {
            text: "What's your current job title?",
            type: "open",
            key: "global:job_title",

        },
        {
            text: "At what company?",
            type: "open",
            key: "global:current_company",
        },
        {
            text: "Are you already interviewing for a new job?",
            type: "boolean",
            key: "global:interviewing",
            editable: true,
        },
        {
            text: "What role(s) are you interviewing for?",
            type: "open",
            key: "global:interviewing_for",
            requires: {
                "global:interviewing": "Yes",
            }
        },
        {
            text: "At what company(ies) are you interviewing?",
            type: "open",
            key: "global:interviewing_at",
            requires: {
                "global:interviewing": "Yes"
            }
        }
    ]
}

export default OnboardingQuestionnaire