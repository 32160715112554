import { create } from 'zustand';
import React, { ReactNode, useEffect, useState } from 'react';
import logo from './transparent.png';
interface User {
    id: string | null;
    email: string | null;
    name: string | null;
}



export interface AppState {
    user: User | null;
    getUser: () => void;
}

const ANONYMOUS_USER: User = {
    id: null,
    email: null,
    name: null,
};

export const getUserFromApi = async () => {
    const response = await fetch(
        '/api/auth/me', { credentials: 'include' }
    );
    if (response.status === 401) {
        return ANONYMOUS_USER;
    }
    return response.json();
}


export const useAppStore = create<AppState>((set) => ({
    user: null,
    getUser: () => {
        getUserFromApi().then((user) => {
            set({ user });
        });
    },
}));


interface StateWrapperProps {
    children: ReactNode;  // Add this to accept children
}
const StateWrapper = ({ children }: StateWrapperProps) => {
    const { user, getUser } = useAppStore();
    const logoRef = React.useRef<HTMLImageElement>(null);
    const [loaded, setLoaded] = useState(false)  
    
  
    useEffect(() => {
      const logo = logoRef.current;
      if (logo && user && !loaded) {
        logo.style.opacity = '1';
        logo.style.transition = 'opacity 0.5s, width 0.5s';
        setTimeout(() => {
          logo.style.opacity = '0';
          logo.style.width = '300px';
          setTimeout(() => {
            logo.style.display = 'none'; 
            setLoaded(true)
          }, 500);
        }, 100);
      }
    }, [user])

    useEffect(() => { getUser() }, [getUser]);

    return (
        <>
            {loaded && children}
            {!loaded &&
                <div id='loading'>
                    <img ref={logoRef} src={logo} alt='prefiQuest' />
                </div>}
        </>
    );
};

export default StateWrapper;
