import React, { useEffect, useState } from 'react';
import useSound from 'use-sound'
import logo from './transparent.png'
import apply from './assets/images/graphics/apply.svg';
import education from './assets/images/graphics/education.png';
import errorImage from './assets/images/graphics/error.png';
import gold from './assets/images/gold.svg';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Alert, Badge, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Nav, Navbar, NavDropdown, Placeholder, ProgressBar, Row, Spinner } from 'react-bootstrap';
import OnboardingQuestionnaire from './data/onboarding';
import ConfettiExplosion from 'react-confetti-explosion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faArrowRight, faArrowsLeftRight, faBookOpen, faBookOpenReader, faBrain, faCheck, faClock, faClockFour, faClockRotateLeft, faClose, faCodePullRequest, faEllipsisVertical, faForwardFast, faForwardStep, faHamburger, faHome, faHomeAlt, faHomeUser, faList, faList12, faListAlt, faListDots, faLock, faMagnifyingGlass, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { useAppStore } from './StateWrapper';


import {
  BrowserRouter,
  createBrowserRouter,
  Link,
  RouterProvider,
  useParams,
  useRouteError
} from "react-router-dom";


// const errorSound = require('./sounds/Soft Fail.mp3')
const errorSound = require('./sounds/Soft Fail.mp3')
const okSound = require('./sounds/Selection.mp3')
// const okSound = require('./sounds/win/Win 4.mp3')
const selectSound = require('./sounds/Select.wav')
// const victorySound = require('./sounds/Selection.mp3')
const victorySound = require('./sounds/Victory.mp3')





type LoadingProps = {
  doneLoading: () => void;
}


type Question = {
  text: string;
  type: 'multiple' | 'input' | "boolean" | "open";
  options?: { [key: string]: string };
  correct?: string;
  requires?: { [key: string]: any };
  editable?: boolean
}

type Questionnaire = {
  questions: Question[];
  done_text: string;
  done_title: string;
  done_button_text: string;
}

type QuestionnaireProps = {
  data: Questionnaire;
  onTerminate: (answers: any) => void
  onCancel?: () => void
}

const Statuses = {
  NA: -1,
  OK: 1,
  NOK: 0
}




const Dashboard = () => {
  const { user } = useAppStore()
  const [quests, setQuests] = useState([])

  useState(() => {
    if (quests.length === 0) {
      fetch('/api/quests').then((resp) => {
        resp.json().then((data) => {
          if (data.quests) {
            setQuests(data.quests)
          }
        })
      })
    }
  })

  return <AppLayout
    content={<>
      <div className='dashboard-body'>
        <div className='text-center'>
          <img src={education} />
        </div>
        {quests.length === 0 && <>
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
          <Placeholder as="p" animation="wave">
            <Placeholder xs={12} />
          </Placeholder>
        </>}

        {/* Show stats: streak, points, learning paths */}
        <Container className={"stats"}>
          <Row>
            <Col xs="6">

              <Alert variant='secondary'>
                <h1>1</h1>
                <small>Streak</small>
              </Alert>
            </Col>
            {/* <Col xs="4">

              <Alert variant='success'>
                <h1>{quests.skill_points}</h1>
                <small>Skill Points</small>
              </Alert>
            </Col> */}
            <Col xs="6">
              <Alert variant='primary'>

                <h1>{quests.length}</h1>
                <small>Quests</small>
              </Alert>

            </Col>
          </Row>
        </Container>


        {/* {!user?.email && <Alert variant='info'><small>Register your email to ensure your progress is saved.</small></Alert>} */}

        {quests.length > 0 && quests.map((quest: any) => {
          return <Card as={Link as any} to={`/quest/${quest.id}`} key={quest.id} style={{ margin: "10px 0" }} className='quest'>
            <Card.Body>
              <Card.Title>{quest.title}</Card.Title>
              {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}

              <Card.Text>
                <ProgressBar animated now={quest.ready ? quest.progress * 100 : 0} variant='success' />
                {quest.ready && <div className='text-center' style={{ margin: "10px 0" }}>
                  <Badge bg="info">In Progress</Badge> &nbsp; &nbsp;
                  {/* <Badge bg="secondary">Level: 1</Badge> &nbsp; &nbsp; */}
                  <Badge bg="primary">Topics: {quest.topics.length}</Badge>&nbsp; &nbsp;
                  <Badge bg="success">Skill Points: {quest.skill_points}</Badge>
                </div>}
                {!quest.ready && <div className='text-center' style={{ margin: "10px 0" }}>
                  <Badge bg="secondary">Preparing...</Badge> &nbsp; &nbsp;
                </div>}
              </Card.Text>

            </Card.Body>
          </Card>
        })}

        <Button as={Link as any} to="/new" variant="primary" size="lg" style={{ width: "100%" }}>
          Start a New Learning Path
        </Button>
      </div>

    </>}
    footer={<>

    </>}
  />
}

const ErrorPage = () => {
  const error = useRouteError();

  return <AppLayout content={<div className='error-page'>
    <img src={errorImage} style={{ margin: '20px 0' }} />

    <Alert variant='warning' className={'text-center'}>
      <i>{(error as any).statusText || (error as any).message}</i>
    </Alert>
  </div>
  }
    footer={
      <Button as={Link as any} to="/" variant='secondary' size='lg'><FontAwesomeIcon icon={faHomeUser} /> Go back to home</Button>
    }
  />
}


const QuestPage = () => {
  let { id } = useParams();
  const [quest, setQuest] = useState()
  const [doingTest, setDoingTest] = useState<any | null>(null)
  const [questionnaire, setQuestionnaire] = useState<any | null>(null)
  const refreshQuest = () => {
    if (id !== null) {
      fetch(`/api/quests/${id}`).then((resp) => {
        return resp.json()
      }).then((data) => {
        setQuest(data)
      })
    }
  }


  const loadQuestionnaire = (id: string) => {
    fetch(`/api/topics/${id}`).then((resp) => {
      return resp.json()
    }).then((data) => {
      setQuestionnaire(data)
    })
  }

  useEffect(refreshQuest, [id])

  useEffect(() => {
    if (doingTest && doingTest.id) {
      loadQuestionnaire(doingTest.id)
    } else {
      refreshQuest()
    }
  }, [doingTest])


  const saveAnswers = (answers: any) => {
    if(!answers){
      setDoingTest(null)
      return
    }
    fetch(`/api/quests/${id}/answers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        answers: answers,
        topic_id: doingTest.id
      })
    }).then((resp) => {
      if (resp.status === 200) {
        setDoingTest(null)
        refreshQuest()
      }
    })
  }



  if (!quest) {
    return <></>
  } if (doingTest !== null) {
    return (<>
      {!questionnaire && <AppLayout content={<>...</>} />}
      {questionnaire && <Questionnaire
        data={questionnaire as Questionnaire}
        onTerminate={saveAnswers}
        onCancel={() => setDoingTest(null)}
      />}
    </>
    )
  } else
    return <AppLayout
      content={<div className={'quest-page'}>

        <Container>
          <Row>
            <Col>

              <Card key={(quest as any).id} style={{ margin: "10px 0" }} className='quest'>
                <Card.Body>
                  <Card.Title><h2>{(quest as any).title}</h2></Card.Title>
                  {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}

                  <Card.Text>
                    <ProgressBar animated now={(quest as any).ready ? ((quest as any).progress * 100) : 0} variant='primary' />
                    {(quest as any).ready && <div className='text-center' style={{ margin: "10px 0" }}>
                      <Badge bg="info">In Progress</Badge> &nbsp; &nbsp;
                      {/* <Badge bg="secondary">Level: 1</Badge> &nbsp; &nbsp; */}
                      <Badge bg="primary">Topics: {(quest as any).topics.length}</Badge>&nbsp; &nbsp;
                      <Badge bg="success">Skill Points: {(quest as any).skill_points}</Badge>
                    </div>}
                    {!(quest as any).ready && <div className='text-center' style={{ margin: "10px 0" }}>
                      <Badge bg="secondary">Preparing...</Badge> &nbsp; &nbsp;
                    </div>}
                  </Card.Text>

                </Card.Body>
              </Card>



            </Col>
          </Row>
          <Row>
            {(quest as any).topics.map((topic_dict: any, idx: number) => {
              const topic = topic_dict.title
              const fp = topic.indexOf('->')
              const open = topic === (quest as any).next_topic || (quest as any).topic_progress[topic] !== undefined
              const shortTopic = fp > 0 ? topic.substring(fp + 2).trim() : topic
              const progress = ((quest as any).topic_progress[topic] || 0) * 100;


              return (
                <Col
                  key={idx}
                  xs={idx !== 0 && (idx % 4 === 0 || (idx + 1) % 4 === 0) ? 6 : 12}
                >
                  <div className={'topic ' + (open ? 'open' : 'pending')}>
                    <Alert variant={open ? "primary" : "secondary"} onClick={() => open && setDoingTest(topic_dict)}>
                      {open && <Badge><FontAwesomeIcon icon={faListDots} /></Badge>}
                      <div className='wrapper'>
                        <FontAwesomeIcon icon={open ? faBookOpenReader : faLock} size='3x' color='#ccc' />
                        {open && <ProgressBar now={progress} />}
                      </div>

                    </Alert>

                    <small>{shortTopic}</small>
                  </div>


                </Col>
              )
            })}
          </Row>
        </Container>
      </div>}
    />
}



const Questionnaire = ({ data, onTerminate, onCancel }: QuestionnaireProps) => {
  const [playError] = useSound(errorSound)
  const [playOk] = useSound(okSound)
  const [playVictory] = useSound(victorySound)

  const [playSelect] = useSound(selectSound)

  const [isDone, setIsDone] = React.useState(false);

  const [answer, setAnswer] = React.useState<string | null>(null);
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [question, setQuestion] = React.useState(data.questions[0]);
  const [responses, setResponses] = React.useState({})
  const [status, setStatus] = React.useState(Statuses.NA)

  const saveAnswer = (opt: string) => {
    if (answer && (!question.editable || answer === opt) && question.type != "open") return

    const correct = !question.correct || question.correct === opt

    setAnswer(opt)
    setStatus(correct ? Statuses.OK : Statuses.NOK)
    setResponses((responses) => {
      const newResponse = {}
      // @ts-ignore
      newResponse[question.key] = {
        answer: opt,
        correct: correct
      }
      return {
        ...responses, ...newResponse
      }
    })

    if (question.type != "open")
      if (correct) {
        playOk()
      } else {
        playError()
      }
  }

  const canTakeIt = (question: Question) => {
    if (question.requires) {
      const results = Object.keys(question.requires).map((key) => {
        // @ts-ignore
        return responses[key].answer === question.requires[key]
      })
      return !results.includes(false)
    }
    return true
  }

  const nextQuestion = () => {
    setAnswer(null)
    setStatus(-1)
    let nqi = questionIndex + 1
    while (nqi < data.questions.length) {
      const nq = data.questions[questionIndex + 1];

      if (canTakeIt(nq)) {
        setQuestionIndex(nqi)
        setQuestion(nq)
        return
      }
      nqi += 1
    }
    setIsDone(true)
  }

  const getQuestionVariant = (key: string) => {
    if (status === Statuses.OK && answer === key) {
      return "success"
    } else if (status === Statuses.NOK && answer === key) {
      return "danger"
    }

    return "primary"
  }

  if (isDone) {
    playVictory()
    return (
      <main>
        <section className='question'>
          <div className='content'>
            <div className='answer'>
              <div className="text-center">
                <img src={gold} width={100} alt='gold' />

                <ConfettiExplosion />

                <h1>{data.done_title}</h1>
                <p>{data.done_text}</p>
              </div>
            </div>
            <Button size="lg" onClick={() => onTerminate(responses)}>
              {data.done_button_text || "Continue"} &nbsp; &nbsp; <FontAwesomeIcon icon={faArrowCircleRight} size='xl' />
            </Button>
          </div>
        </section>
      </main>
    )
  }
  return (
    <AppLayout
      header={<>
        <div style={{display: "flex"}}>
          <span style={{margin: "0 10px", cursor: "pointer"}} onClick={onCancel} ><FontAwesomeIcon  icon={faClose}/></span>
          <ProgressBar now={questionIndex / data.questions.length * 100} style={{flexGrow: 1}}/>
        </div>
        <br />
        <h1>{question.text}</h1>
      </>}
      content={
        <>
          {question.type === 'multiple' && <>
            {question.options && Object.keys(question.options).map((key, idx) => {
              return (

                <Button
                  key={idx}
                  size="lg"
                  className='answer'
                  disabled={!!answer && !question.editable}
                  onClick={() => saveAnswer(key)}

                  variant={getQuestionVariant(key)}>{question.options && question.options[key as keyof typeof question.options]}</Button>
              )
            })}
          </>}

          {question.type === 'open' && <>
            <Form.Control
              as="textarea"
              className="answer"
              placeholder={question.text}
              value={answer || ""}
              rows={3}
              // onKeyUp={(e)=> {
              //   if()
              // }}
              onChange={(e) => {
                saveAnswer(e.target.value)
              }}
            />
          </>}


          {question.type === 'boolean' && <>
            {["Yes", "No"].map((key) => {
              return (

                <Button
                  key={key}
                  size="lg"
                  className='answer'
                  disabled={!!answer && !question.editable}
                  onClick={() => saveAnswer(key === "Yes" ? "Yes" : "No")}

                  variant={getQuestionVariant(key)}>{key}</Button>
              )
            })}
          </>}

        </>
      }
      footer={<>
        {answer && status === Statuses.NOK && <Alert variant='danger'>
          The correct answer is: {question.options && question.options[question.correct as keyof typeof question.options]}
        </Alert>}
        {answer && status === Statuses.OK && question.correct && <Alert variant='primary'>
          Correct!
        </Alert>}
        <Button
          disabled={!answer}
          size='lg'
          variant={status === Statuses.NA ? "outline-secondary" : (status === Statuses.OK ? "primary" : "outline-danger")}
          onClick={nextQuestion}
        >Continue</Button>
      </>}
    />
  );
}


type AppLayoutProps = {
  header?: any;
  content: any;
  footer?: any;
}

const AppLayout = ({ header, content, footer }: AppLayoutProps) => {
  return (
    <main>
      <Badge as={Link as any} to="/beta" bg='info' className='beta-badge' >Beta Preview</Badge>
      <section className="app-layout">
        {header && <header>{header}</header>}
        <div className="content">{content}</div>
        {footer && <footer>{footer}</footer>}
      </section>
    </main>
  )
}




const Begin = () => {

  const messages = [
    "Gathering Data...",
    "Analyzing Details...",
    "Identifying Key Skills Needed...",
    "Matching Skills with Learning Modules...",
    "Building Your Customized Learning Path...",
    "Optimizing Learning Journey...",
    "Setting Up Progress Tracking...",
    "Almost There! Finalizing Setup..."
  ];

  const { user, getUser } = useAppStore()

  const [url, setUrl] = useState("")
  const [analyzing, setAnalyzing] = useState(false)
  const [analyzingStep, setAnalyzingStep] = useState("")
  const [currentMessage, setCurrentMessage] = useState(-1)
  const [allReady, setAllReady] = useState(false)
  const [skip, setSkip] = useState(false)

  const [goDashboard, setGoDashboard] = useState(false)

  const vurl = () => {
    try {
      const parsed = new URL(url);
      if (['http:', 'https:'].indexOf(parsed.protocol) === -1) {
        return false
      }

      if (['127.0.0.1', 'localhost'].indexOf(parsed.hostname) >= 0) {
        return false
      }
      const parts = parsed.hostname.split('.')
      if (parts.length === 1) {
        return false
      }

      if (parts.indexOf('') >= 0) {
        return false;
      }

      if (parts[parts.length - 1] === 'local') {
        return false
      }

      return true;
    } catch (err) {
      return false;
    }
  }

  const isValidUrl = () => {
    return url.length > 0 && vurl()
  }
  const isInvalidUrl = () => {
    return url.length > 0 && !vurl()
  }

  useEffect(() => {
    if (analyzing)
      if (currentMessage < messages.length) {
        setAnalyzingStep(messages[currentMessage])
        setTimeout(() => { setCurrentMessage(currentMessage + 1) }, allReady ? 200 : 1000)
      } else if (allReady) {
        setAnalyzingStep("Your Personalized Learning Path Is Ready!")
        setGoDashboard(true)
      }
  }, [currentMessage])

  const skipJobDescription = () => {
    setSkip(true)
  }

  const analyzeJobDescription = () => {
    setAnalyzing(true)
    setCurrentMessage(0)
    fetch('/api/quests/create/from-jd', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ url })
    }).then((res) => {
      if (res.status === 200) {
        setAllReady(true)
        getUser()
        res.json().then((data) => {

          setAnalyzingStep("Your Personalized Learning Path Is Ready!")
          setCurrentMessage(messages.length - 1)
          setGoDashboard(true)
        })
      }
    })
  }

  const analyzeCareer = (answers: any) => {
    setAnalyzing(true)
    setSkip(false)
    setCurrentMessage(0)
    fetch('/api/quests/create/from-answers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ answers: answers })
    }).then((res) => {
      if (res.status === 200) {
        setAllReady(true)
        getUser()
        res.json().then((data) => {

          setAnalyzingStep("Your Personalized Learning Path Is Ready!")
          setCurrentMessage(messages.length - 1)
          setGoDashboard(true)
        })
      }
    })
  }

  if (skip) {
    return (
      <Questionnaire data={OnboardingQuestionnaire as Questionnaire} onTerminate={analyzeCareer} />
    )
  }

  return <AppLayout
    header={<>
      <img className="logo" src={logo} />
      <h2>Helping you Ace your next Job Interview</h2>
      <small>Get personalized guidance based on real job descriptions to excel in your interviews.</small>
    </>}
    content={<>
      {analyzing ? <>
        <small style={{ textAlign: 'center' }}>
          {!allReady && <Spinner animation="border" size="sm" />} {' '}
          {analyzingStep}
          {goDashboard && <div>
            <br />
            <Button as={Link as any} size="lg" to="/" >Start Your Quest!</Button>
          </div>}
        </small>
      </> : <>

        <Form.Label htmlFor="jdurl">Enter a Job Description URL</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
          <Form.Control
            as="input"
            id="jdurl"
            placeholder="https://www.linkedin.com/jobs/view/123..."
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            isValid={isValidUrl()}
            isInvalid={isInvalidUrl()}
          />
        </InputGroup>

        <Form.Text id="jdurl" muted>
          Paste a job description URL, and we’ll prepare a tailored learning path for your interview.
        </Form.Text>
        <Container>
          <Row>
            <Col xs={6}>
              <Button variant="outline-primary" onClick={skipJobDescription}>
                Skip Job Description
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                variant="primary"
                disabled={!isValidUrl()}
                onClick={analyzeJobDescription}
              >
                Analyze Job Description
              </Button>
            </Col>
          </Row>
        </Container>
      </>}</>
    }
    footer={<>
      <img src={apply} />
      <div>
        {/* <em>I already have an account. <a href="#">Sign in</a></em> */}
      </div>
    </>}
  />
}


const App = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const { user } = useAppStore()

  const save = (answers: any) => {
    setCompleted(true)
    setSaving(true)
  }

  if (user?.id === null) {
    return <BrowserRouter><Begin /></BrowserRouter>
  } else {
    return <RouterProvider router={router} />
  }

  return (
    <>
      {!completed &&
        <Questionnaire
          data={OnboardingQuestionnaire as Questionnaire}
          onTerminate={save}
        />
      }

      {saving &&
        <AppLayout content={"Saving..."} />
      }
    </>
  );
}


const BetaDisclaimer = () => {
  return <AppLayout content={<>
    <div>
      <div style={{ textAlign: "center" }}><img src={logo} /></div>

      <div style={{ textAlign: "center" }}><a href="https://www.producthunt.com/posts/prefiquest?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-prefiquest" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=487953&theme=neutral" alt="prefiQuest - Gamified&#0032;Career&#0032;and&#0032;Interview&#0032;Preparation | Product Hunt" style={{ width: "250px", height: "54px" }} width="250" height="54" /></a></div>

      <h1>Welcome to Our Beta Preview!</h1>



      <p>Thank you for joining us on this exciting journey as we continue to develop and refine <strong>prefiQuest</strong>. Please note the following as you navigate through our app:</p>

      <h3>Beta Version</h3>
      <p>You are currently accessing a beta preview of <strong>prefiQuest</strong>. This means both the functionality and content of the app are actively being reviewed and improved.</p>

      <h3>Ongoing Improvements</h3>
      <p>We are dedicated to enhancing <strong>prefiQuest</strong>, and you may notice regular updates and changes. Our team is working hard to expand the features and polish the overall experience.</p>

      <h3>Expect Some Bugs</h3>
      <p>As with any beta software, the app is not free from bugs. We appreciate your understanding and patience as we work towards creating a more stable and reliable platform.</p>

      <h3>We Value Your Feedback</h3>
      <p>Your input is invaluable to us. Please share your thoughts, experiences, and any issues you encounter on our {' '}
        <a href="https://www.producthunt.com/posts/prefiquest" target="_blank">ProductHunt page</a>. Your feedback plays a crucial role in helping us improve and tailor <strong>prefiQuest</strong> to better meet your needs.
      </p>

      <p>Thank you for your support and participation in making <strong>prefiQuest</strong> better. Enjoy exploring what we have built so far!</p>
    </div>

  </>} />
}



const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/new",
    element: <Begin />
  },
  {
    path: '/quest/:id',
    element: <QuestPage />
  },
  {
    path: '/beta',
    element: <BetaDisclaimer />
  }
]);

export default App;
